import styled from "styled-components";
// @ts-ignore
import backgroundImage from "../../../images/common/paprotki-o-nas.svg"
import {MD_MIN_SIZE, SM_MAX_SIZE} from "../../../common/util/ViewSizeUtils";

export const StyledAboutUsSection = styled.div`
  @media only screen and (min-width: ${MD_MIN_SIZE}px) {
    height: calc(100vh - 17px);
  }

  background-image: url(${backgroundImage});
  background-position: center center;
  background-repeat: no-repeat;
  //background-attachment: fixed;
  background-size: cover;

  .page-title-container {
    mix-blend-mode: difference;
    position: absolute;
    @media only screen and (min-width: ${MD_MIN_SIZE}px) {
      padding-top: 40px !important;
    }
  }

  .page-left-panel {
    @media only screen and (min-width: ${MD_MIN_SIZE}px) {
      margin-left: 15%;
    }
  }

  .page-right-panel {
    @media only screen and (min-width: ${MD_MIN_SIZE}px) {
      margin-right: 15%;
    }
  }

  .title-row {
    @media only screen and (max-width: ${SM_MAX_SIZE}px) {
      height: 180px;
    }
  }
`;
