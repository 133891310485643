import React, {FC} from "react";
import {StyledAboutUsElement} from "./AboutUsElement.style";
import {Translate} from "../../../../lang/Translate.component";
import {ImageCustom} from "../../../../common/components/image/ImageCustom.component";
import {ImageProps} from "../../../../common/components/carousel/service/Carousel.service";

export interface AboutUsElementProps {
    color?: string;
    image: ImageProps;
    text: string;
}

export const AboutUsElement: FC<AboutUsElementProps> = (props: AboutUsElementProps) => {
    return <StyledAboutUsElement {...props} className="col-xs-12 col-sm-12 col-md-4 col-lg-4">
        <div className="image-box box">
            <ImageCustom image={props.image}/>
            <div className={"text"}>
                <Translate translationKey={props.text}/>
            </div>
        </div>
    </StyledAboutUsElement>;
};
