import React, {FC} from "react";
import {StyledButtonWithLines} from "./ButtonWithLines.style";
import {LocalizedLink} from "../localizedLink/LocalizedLink.component";
import {Translate} from "../../../lang/Translate.component";

export interface LineProps {
    top: string;
    left: string;
    length: string;
}

export interface ButtonWithLinesProps {
    link?: string;
    onClick?: () => void;

    label: string;

    topLine: LineProps;
    bottomLine: LineProps;
    leftLine: LineProps;
    rightLine: LineProps;

    linesWidth: string;
    linesColor: string;
    textColor: string;
    width: string;
    height: string;
}

export const ButtonWithLines: FC<ButtonWithLinesProps> = (props: ButtonWithLinesProps) => {
    return <StyledButtonWithLines {...props}>
        {props.link && <LocalizedLink className={"clickable"} to={props.link as string} label={props.label}/>}
        {props.onClick && <div className={"clickable"} onClick={props.onClick}><Translate translationKey={props.label}/></div>}
        <div className={"line-top"}/>
        <div className={"line-bottom"}/>
        <div className={"line-left"}/>
        <div className={"line-right"}/>
    </StyledButtonWithLines>;
};
