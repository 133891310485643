import React, {FC} from "react";
import {StyledMainSection} from "./MainSection.style";
import {Translate} from "../../../lang/Translate.component";
import {LanguageSelector} from "../../../common/components/languageSelector/LanguageSelector.component";
import {LocalizedLink} from "../../../common/components/localizedLink/LocalizedLink.component";
import {getBookingsLink, getPricingLink} from "../../../common/components/menu/service/Links.service";

export const MainSection: FC = () => {
    return <StyledMainSection>
        <div className={"main-section"}>
            <h1><Translate translationKey={"HOME_PAGE_TITLE"}/></h1>
            <h2><Translate translationKey={"HOME_PAGE_DESCRIPTION"}/></h2>
            <div className={"buttons"}>
                <LocalizedLink to={getPricingLink()} className={"pricing-button"} label={"PRICING"}/>
                <LocalizedLink to={getBookingsLink()} className={"bookings-button"} label={"BOOKINGS"}/>
            </div>
        </div>
        <LanguageSelector/>
    </StyledMainSection>;
};
