import styled from "styled-components";
import {AboutUsElementProps} from "./AboutUsElement.component";
import {MD_MIN_SIZE, SM_MAX_SIZE} from "../../../../common/util/ViewSizeUtils";

export const StyledAboutUsElement = styled.div<AboutUsElementProps>`
  @media only screen and (min-width: ${MD_MIN_SIZE}px) {
    height: calc(76vh);
  }

  @media only screen and (max-width: ${SM_MAX_SIZE}px) {
    height: 100vh;
    display: flex;
    align-items: center;
  }

  background-color: ${props => props.color};

  .image-box {
    width: 100%;
    text-align: center;
    @media only screen and (min-width: ${MD_MIN_SIZE}px) {
      padding-top: 188px;
    }
  }
  
  .text {
    mix-blend-mode: difference;
    font-size: 20px;
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 40px;
    font-family: bely;
    white-space: pre-wrap;

    @media only screen and (max-width: ${SM_MAX_SIZE}px) {
      max-width: 600px;
      margin-left: auto;
      margin-right: auto;
    }
  }
  
  img {
    width: 241px;
    height: 257px;
  }
`;
