import styled from "styled-components";
import {SM_MIN_SIZE} from "../../../common/util/ViewSizeUtils";

export const StyledInformationModal = styled.div`

    img {
      max-width: calc(100vw - 25px);
      @media only screen and (min-width: ${SM_MIN_SIZE}px) {
        max-width: calc(100vw - 100px);
      }
    }
`;
