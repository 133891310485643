import React, {FC} from "react";
import {StyledAboutUsSection} from "./AboutUsSection.style";
import {ViewArtistsButton} from "./viewArtistsButton/ViewArtistsButton.component";
import {Colors} from "../../../common/style/ColorUtils";
import {Title} from "../../../common/components/title/Title.component";
import {AboutUsElement} from "./aboutUsElement/AboutUsElement.component";
import {Images} from "../../../images/Images";

interface AboutUsSectionProps {
}

export const AboutUsSection: FC<AboutUsSectionProps> = (props: AboutUsSectionProps) => {
    return <StyledAboutUsSection>
        <div className="row title-row">
            <Title title={"ABOUT_US"} panelsColor={Colors.WHITE}/>
        </div>
        <div className="row">
            <AboutUsElement
                image={Images.TATTOO_MACHINE}
                text={"ABOUT_US_FIRST_BLOCK"}
            />
            <AboutUsElement
                image={Images.PALETTE}
                color={Colors.WHITE}
                text={"ABOUT_US_SECOND_BLOCK"}
            />
            <AboutUsElement
                image={Images.MASK}
                text={"ABOUT_US_THIRD_BLOCK"}
            />
        </div>
        <ViewArtistsButton/>
    </StyledAboutUsSection>;
};
