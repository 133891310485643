import styled from "styled-components";
import {Colors} from "../../../common/style/ColorUtils";
// @ts-ignore
import backgroundImage from "../../../images/common/paprotki-galeria.svg"

export const StyledGallerySection = styled.div`
  min-height: calc(100vh - 17px);
  background-color: ${Colors.WHITE};
  background-image: url(${backgroundImage});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  font-size: 0;

  div {
    padding-left: 0;
    padding-right: 0;
  }

  .gallery-container {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
  }

  img {
    width: auto;
  }

  .img-first-size {
    @media only screen and (min-width: 1322px) {
      height: 426px;
    }

    padding-bottom: 34px;
    @media only screen and (max-width: 1321px) {
      max-width: 63.2375189107413vw;
    }

    @media only screen and (max-width: 1199px) {
      max-width: unset;
      width: calc(100vw - 68px);
    }
  }

  .img-second-size {
    @media only screen and (min-width: 1322px) {
      height: 196px;
    }

    @media only screen and (max-width: 1321px) {
      max-width: 29.95461422087746vw;
    }
    @media only screen and (max-width: 1199px) {
      max-width: unset;
      width: 41.70141784820684vw;
    }
    @media only screen and (max-width: 1023px) {
      width: calc(100vw - 68px);
    }
    padding-bottom: 34px;
  }

  .img-third-size {
    @media only screen and (min-width: 1322px) {
      height: 426px;
    }

    @media only screen and (max-width: 1321px) {
      max-width: 29.95461422087746vw;
      padding-bottom: 34px;
    }
    @media only screen and (max-width: 1199px) {
      max-width: unset;
      padding-bottom: 34px;
      width: 41.50390625vw;
    }
    @media only screen and (max-width: 1023px) {
      width: calc(100vw - 68px);
    }
  }

  .row {
    text-align: center;
  }

  .page-title-container {
    padding-bottom: 20px;
    padding-top: 20px;
  }
`;
