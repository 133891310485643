import styled from "styled-components";
import {ButtonWithLinesProps} from "./ButtonWithLines.component";
import {FontSizes} from "../../style/FontUtils";

export const StyledButtonWithLines = styled.div<ButtonWithLinesProps>`
  width: ${(props: ButtonWithLinesProps) => props.width};
  line-height: ${(props: ButtonWithLinesProps) => props.height};
  text-align: center;
  height: ${(props: ButtonWithLinesProps) => props.height};
  display: flex;

  :hover .line-top {
    margin-left: ${(props: ButtonWithLinesProps) => props.bottomLine.left};
    width: ${(props: ButtonWithLinesProps) => props.bottomLine.length};
    transition: 0.6s;
  }
  
  :hover .line-bottom {
    margin-left: ${(props: ButtonWithLinesProps) => props.topLine.left};
    width: ${(props: ButtonWithLinesProps) => props.topLine.length};
    transition: 0.6s;
  }
  
  :hover .line-left {
    margin-top: ${(props: ButtonWithLinesProps) => props.rightLine.top};
    height: ${(props: ButtonWithLinesProps) => props.rightLine.length};
    transition: 0.6s;
  }
  
  :hover .line-right {
    margin-top: ${(props: ButtonWithLinesProps) => props.leftLine.top};
    height: ${(props: ButtonWithLinesProps) => props.leftLine.length};
    transition: 0.6s;
  }

  .line-top {
    position: absolute;
    height: ${(props: ButtonWithLinesProps) => props.linesWidth};
    background-color: ${(props: ButtonWithLinesProps) => props.linesColor};
    transition: 0.6s;

    width: ${(props: ButtonWithLinesProps) => props.topLine.length};
    margin-top: ${(props: ButtonWithLinesProps) => props.topLine.top};
    margin-left: ${(props: ButtonWithLinesProps) => props.topLine.left};
  }

  .line-bottom {
    position: absolute;
    height: ${(props: ButtonWithLinesProps) => props.linesWidth};
    background-color: ${(props: ButtonWithLinesProps) => props.linesColor};
    transition: 0.6s;

    width: ${(props: ButtonWithLinesProps) => props.bottomLine.length};
    margin-top: ${(props: ButtonWithLinesProps) => props.bottomLine.top};
    margin-left: ${(props: ButtonWithLinesProps) => props.bottomLine.left};
  }
  
  .line-left {
    width: ${(props: ButtonWithLinesProps) => props.linesWidth};
    position: absolute;
    background-color: ${(props: ButtonWithLinesProps) => props.linesColor};
    transition: 0.6s;

    height: ${(props: ButtonWithLinesProps) => props.leftLine.length};
    margin-top: ${(props: ButtonWithLinesProps) => props.leftLine.top};
    margin-left: ${(props: ButtonWithLinesProps) => props.leftLine.left};
  }

  .line-right {
    width: ${(props: ButtonWithLinesProps) => props.linesWidth};
    position: absolute;
    background-color: ${(props: ButtonWithLinesProps) => props.linesColor};
    transition: 0.6s;

    height: ${(props: ButtonWithLinesProps) => props.rightLine.length};
    margin-top: ${(props: ButtonWithLinesProps) => props.rightLine.top};
    margin-left: ${(props: ButtonWithLinesProps) => props.rightLine.left};
  }

  .clickable {
    margin-left: auto;
    margin-right: auto;
    font-family: bely-bold;
    font-size: ${FontSizes.SMALL};
    cursor: pointer;
    color: ${(props: ButtonWithLinesProps) => props.textColor};
  }
`;
