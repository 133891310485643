import styled from "styled-components";
import {FontSizes} from "../../../common/style/FontUtils";
import {Colors} from "../../../common/style/ColorUtils";
import {LG_MIN_SIZE, MD_MAX_SIZE, XS_MAX_SIZE} from "../../../common/util/ViewSizeUtils";

export const StyledMainSection = styled.div`
  .main-section {
    @media only screen and (min-width: ${LG_MIN_SIZE}px) {
      position: absolute;
      top: calc(100vh - 460px);
      padding-left: 4rem;
    }

    @media only screen and (max-width: ${MD_MAX_SIZE}px) {
      position: absolute;
      top: calc(100vh - 460px);
      padding-left: 20px;
    }
    
    @media only screen and (max-width: ${XS_MAX_SIZE}px) {
      position: absolute;
      padding-left: 5px;
      top: calc(100vh - 400px);
    }
  }

  h1 {
    font-family: bely-bold;
    font-weight: 100;
    font-size: ${FontSizes.BIG};
    @media only screen and (max-width: ${XS_MAX_SIZE}px) {
      font-size: 35px;
    }

    margin: 0;
  }

  h2 {
    padding-top: 20px;
    font-weight: 100;
    margin: 0;
    max-width: 1005px;
    font-size: ${FontSizes.SMALL};
    @media only screen and (max-width: ${XS_MAX_SIZE}px) {
      font-size: 22px;
    }
    
    white-space: pre-wrap;
  }

  .buttons {
    padding-top: 3rem;
  }

  .pricing-button {
    display: inline-block;
    text-decoration: none;
    padding-top: 3px;
    padding-bottom: 3px;
    padding-right: 40px;
    padding-left: 40px;

    @media only screen and (max-width: ${XS_MAX_SIZE}px) {
      padding-right: 10px;
      padding-left: 10px;
    }

    font-weight: 100;
    border-radius: 7px;
    width: 111px;
    line-height: 37px;
    text-align: center;
    border: solid ${Colors.WHITE} 4px;
    color: ${Colors.WHITE};
  }

  .bookings-button {
    display: inline-block;
    text-decoration: none;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-right: 40px;
    padding-left: 40px;

    @media only screen and (max-width: ${XS_MAX_SIZE}px) {
      padding-right: 10px;
      padding-left: 10px;
      margin-left: 10px;
    }

    background-color: ${Colors.YELLOW};
    font-weight: bold;
    border-radius: 7px;
    width: 111px;
    line-height: 37px;
    text-align: center;
    color: black;
    mix-blend-mode: lighten;
    margin-left: 32px;
  }
`;
