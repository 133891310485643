import React, {FC, useState} from "react";
import {StyledGallerySection} from "./Gallery.style";
import {Images} from "../../../images/Images";
import {Title} from "../../../common/components/title/Title.component";
import {Colors} from "../../../common/style/ColorUtils";
import {ViewMorePicturesButton} from "./viewMorePicturesButton/ViewMorePicturesButton.component";
import {ImageCustom} from "../../../common/components/image/ImageCustom.component";
import {ModalGallery} from "../../../common/components/modalGallery/ModalGallery.component";
import {Zoya} from "../../artists/service/Zoya.service";
import {Samoleczenie} from "../../artists/service/Samoleczenie.service";
import {Axellent} from "../../artists/service/Axellent.service";
import {Cyzana} from "../../artists/service/Cyzana.service";
import {Gabi} from "../../artists/service/Gabi.service";
import {Tala} from "../../artists/service/Tala.service";

interface GallerySectionProps {
}

export const GallerySection: FC<GallerySectionProps> = (props: GallerySectionProps) => {
    const [isGalleryVisible, setIsGalleryVisible] = useState(false);

    const fullGallery = [
        Zoya.portfolio[0],
        Samoleczenie.portfolio[0],
        Axellent.portfolio[0],
        Cyzana.portfolio[0],
        Gabi.portfolio[0],
        Tala.portfolio[0],

        Zoya.portfolio[2],
        Samoleczenie.portfolio[2],
        Axellent.portfolio[2],
        Cyzana.portfolio[2],
        Gabi.portfolio[1],
        Tala.portfolio[2],

        Zoya.portfolio[4],
        Samoleczenie.portfolio[4],
        Axellent.portfolio[4],
        Cyzana.portfolio[4],
        Tala.portfolio[4],
    ];
    return <StyledGallerySection>
        <Title title={"GALLERY"} panelsColor={Colors.YELLOW} textColor={Colors.BLACK}/>
        <div className={"row gallery-container"}>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-8">
                <div className={"row padding-bottom"}>
                    <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                        <ImageCustom className={"img-first-size"} image={Images.GALLERY_PICTURE_1}/>
                    </div>
                </div>
                <div className={"row"}>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <ImageCustom className={"img-third-size"} image={Images.GALLERY_PICTURE_4}/>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-6">
                        <div className={"row"}>
                            <div className="padding-bottom col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <ImageCustom className={"img-second-size"} image={Images.GALLERY_PICTURE_5}/>
                            </div>
                        </div>
                        <div className={"row"}>
                            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-12">
                                <ImageCustom className={"img-second-size"} image={Images.GALLERY_PICTURE_6}/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="col-xs-12 col-sm-12 col-md-12 col-lg-4">
                <div className={"row"}>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-12">
                        <div className="padding-bottom col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <ImageCustom className={"img-second-size"} image={Images.GALLERY_PICTURE_2}/>
                        </div>
                        <div className="padding-bottom col-xs-12 col-sm-12 col-md-12 col-lg-12">
                            <ImageCustom className={"img-second-size"} image={Images.GALLERY_PICTURE_3}/>
                        </div>
                    </div>
                    <div className="col-xs-12 col-sm-12 col-md-6 col-lg-12">
                        <ImageCustom className={"img-third-size"} image={Images.GALLERY_PICTURE_7}/>
                    </div>
                </div>
            </div>
        </div>
        <ViewMorePicturesButton onClick={() => setIsGalleryVisible(true)}/>
        <ModalGallery
            closeModal={() => setIsGalleryVisible(false)}
            isOpen={isGalleryVisible}
            images={fullGallery}
        />
    </StyledGallerySection>;
};
