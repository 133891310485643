import React, {FC, useEffect, useState} from "react";
import {StyledInformationModal} from "./InformationModal.style";
import {Modal} from "../../../common/components/modal/Modal.component";
import {ImageProps} from "../../../common/components/carousel/service/Carousel.service";
import {DayRange} from "../../../common/util/DayRange";
import {isNotDefined} from "../../../common/util/ObjectUtils";
import {Day} from "../../../common/util/Day";
import {ImageCustom} from "../../../common/components/image/ImageCustom.component";

interface InformationModalProps {
    informationKey: string;
    informationPicture: ImageProps;
    informationLink?: string;
    period: DayRange;
}

export const InformationModal: FC<InformationModalProps> = (props: InformationModalProps) => {
    const [isModalVisible, setIsModalVisible] = useState(false);

    useEffect(()=>{
        const item: string = window.localStorage.getItem(props.informationKey);
        setIsModalVisible(shouldModalBeVisible(item));
    }, []);
    
    const shouldModalBeVisible = (item: string): boolean => {
        return (isNotDefined(item) || item !== "showed") && props.period.containsDay(Day.today());
    } 

    const onModalClose = () => {
        window.localStorage.setItem(props.informationKey, "showed");
        setIsModalVisible(false);
    }

    return <StyledInformationModal>
        <Modal isOpen={isModalVisible} closeModal={onModalClose}>
            <ImageCustom image={props.informationPicture}/>
        </Modal>
    </StyledInformationModal>;
};
