import React, {FC} from "react";
import {ButtonWithLines, LineProps} from "../../../../common/components/buttonWithLines/ButtonWithLines.component";
import {Colors} from "../../../../common/style/ColorUtils";

interface ViewMorePicturesButton {
    onClick: () => void;
}

export const ViewMorePicturesButton: FC<ViewMorePicturesButton> = (props: ViewMorePicturesButton) => {
    const topLineProps: LineProps = {
        left: "6px", length: "70px", top: "12px"
    };

    const bottomLineProps: LineProps = {
        left: "200px", length: "100px", top: "53px"
    };

    const leftLineProps: LineProps = {
        left: "13px", length: "35px", top: "5px"
    };

    const rightLineProps: LineProps = {
        left: "280px", length: "38px", top: "25px"
    };

    return <div className="row center-xs" style={{paddingTop: "60px", paddingBottom: "60px"}}>
        <div className="box">
            <ButtonWithLines
                width={"290px"}
                height={"60px"}
                topLine={topLineProps}
                bottomLine={bottomLineProps}
                leftLine={leftLineProps}
                rightLine={rightLineProps}
                linesColor={Colors.GRAY}
                textColor={Colors.BLACK}
                linesWidth={"1px"}
                label={"VIEW_MORE"}
                onClick={props.onClick}
            />
        </div>
    </div>;
};
