import * as React from "react"
import {FC} from "react"
import {StyledHomePage} from "./HomePage.style";
import {StyledBackground} from "./Background.style";
import {Menu} from "../../common/components/menu/Menu.component";
import {Spacer} from "../../common/components/spacer/Spacer.style";
import {MainSection} from "./mainSection/MainSection.component";
import {AboutUsSection} from "./aboutUsSection/AboutUsSection.component";
import {GallerySection} from "./gallerySection/Gallery.component";
import {DayRange} from "../../common/util/DayRange";
import {Month} from "../../common/util/Month";
import {Day} from "../../common/util/Day";
import {InformationModal} from "./informationModal/InformationModal.component";
import {Images} from "../../images/Images";

export const HomePageComponent: FC = () => {
    const informationPeriod = new DayRange(
        Day.from(2024, Month.MARCH, 14),
        Day.from(2024, Month.MARCH, 24));
    return <StyledHomePage>
        <StyledBackground>
            <Menu selectedPageName={"index"}/>
            <MainSection/>
        </StyledBackground>
        <Spacer/>
        <AboutUsSection/>
        <GallerySection/>
        <InformationModal
            informationKey={"walkinWiosna2024"}
            period={informationPeriod}
            informationPicture={Images.WALK_IN}
        />
    </StyledHomePage>;
}
