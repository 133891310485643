import styled from "styled-components";
// @ts-ignore
import backgroundImage from "../../images/common/tatuowanie-reki.webp"

export const StyledBackground = styled.div`{
  background-image: url(${backgroundImage});
  background-position: center center;
  background-repeat: no-repeat;
  //background-attachment: fixed;
  background-size: cover;
  height: calc(100vh - 17px);
}`
